import FrontAppChat from "@cospex/client/components/FrontAppChat";
import App from "@cospex/client/parcel/components/App";

export default function Root() {
  return (
    <>
      <FrontAppChat />
      <App />
    </>
  );
}
